import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TitleArea from '../components/TitleArea'
import { Link } from 'gatsby'
import { Container, Columns, Column, Section, Content } from 'bloomer'

export default () => (
  <Layout>
    <SEO title="F.A.Q." alias="faq" />
    <TitleArea title="F.A.Q." />
    <Section>
      <Container className="container--narrow">
        <Columns>
          <Column>
            <Content>
              <h2 id="contents">Contents</h2>
              <h3>Boarding Questions</h3>
              <ul>
                <li>
                  <a href="#q1">
                    What should I bring with my pet for their stay?
                  </a>
                </li>
                <li>
                  <a href="#q2">Should I bring my pet’s own food?</a>
                </li>
                <li>
                  <a href="#q3">
                    How often will my pet be let out into their run?
                  </a>
                </li>
                <li>
                  <a href="#q4">What is your price structure?</a>
                </li>
                <li>
                  <a href="#q5">
                    What type of agreement is made when boarding a pet?
                  </a>
                </li>
                {/* <li>
                  <a href="#q15">Should I get my dog walked?</a>
                </li> */}
              </ul>
              <h3>Grooming Questions</h3>
              <ul>
                <li>
                  <a href="#q6">
                    What should I do if my pet has shown aggression?
                  </a>
                </li>
                <li>
                  <a href="#q7">What do I do if my dog is matted?</a>
                </li>
                <li>
                  <a href="#q8">What if my pet has fleas?</a>
                </li>
                <li>
                  <a href="#q9">What if I must leave my pet overnight?</a>
                </li>
                <li>
                  <a href="#q10">
                    Should I wait on my pet while it is being groomed?
                  </a>
                </li>
                <li>
                  <a href="#q11">
                    What type of agreement is made when having a pet groomed?
                  </a>
                </li>
              </ul>
              <h3>Payment and Other Questions</h3>
              <ul>
                <li>
                  <a href="#q12">When do we pay for your services?</a>
                </li>
                <li>
                  <a href="#q13">What happens in an emergency?</a>
                </li>
                <li>
                  <a href="#q14">What about vaccines?</a>
                </li>
              </ul>
              <p>&nbsp;</p>
              <h2>Boarding Questions</h2>
              <h3 id="q1">What should I bring with my pet for their stay?</h3>
              <p>
                You may want to bring your pet’s bed or blanket and some of
                their toys. This can help your pet feel at home. We require all
                bedding to be clean at arrival.
              </p>
              <h3 id="q2">Should I bring my pet’s own food?</h3>
              <p>
                Yes. Your pet has a more comfortable stay if you bring their own
                food. Some pets are upset by feed changes. We do provide food if
                that is your choice. We also provide stainless steel food bowls
                for all guests.
              </p>
              <h3 id="q3">How often will my dog be let out into their run?</h3>
              <p>Our guests are let out 4 times a day for 15 minutes.</p>
              <h3 id="q4">What is your price structure?</h3>
              <p>
                At Shady Lane Boarding Kennel we charge by the night. So if you
                brought your pet on Friday, for example, and then pick it up
                during our Sunday hours, you would be charged for 2 nights. Our
                rates also vary by number of animals. A full price chart can be
                seen on the page of the service you are looking for.
              </p>
              <h3 id="q5">
                What type of agreement is made when boarding a pet?
              </h3>
              <p>
                A standard&nbsp;
                <a
                  title="Boarding Kennel Agreement"
                  href="http://shadylaneboardingkennel.com/boarding-kennel-agreement/"
                >
                  boarding kennel agreement
                </a>
                &nbsp;is signed by both Shady Lane Boarding Kennel and the Pet
                Owner upon arrival.
              </p>
              {/* <h3 id="q15">Should I get my dog walked?</h3>
              <p>
                Generally, exercise in the large outdoor runs is sufficient.
                However, we do offer{' '}
                <Link to="/dog-walking">dog walking services</Link> and a walk
                does allow us to spend more personal time with your pet.
              </p> */}
              <p>&nbsp;</p>
              <h2>Grooming Questions</h2>
              <h3 id="q6">What should I do if my pet has shown aggression?</h3>
              <p>
                We ask you to inform us, when you drop your pet off, if the pet
                has shown aggression during previous grooming procedures.
                Muzzles may be used to protect both the groomer and the pet.
              </p>
              <h3 id="q7">What do I do if my dog is matted?</h3>
              <p>
                Minor matt removal is included with the regular grooming prices.
                For de-matting there is an additional charge of $5 for every 15
                minutes. During matt removal there is a greater risk of nicks,
                cuts, and abrasions. Urine and moisture can be trapped near the
                pet’s skin, causing irritations. Hair may not regrow the same
                after de-matting procedures. We will not continue to de-matt
                your pet. Prevention is the best defence.
              </p>
              <h3 id="q8">What if my pet has fleas?</h3>
              <p>Your pet will receive a flea bath at an additional charge.</p>
              <h3 id="q9">What if I must leave my pet overnight?</h3>
              <p>
                If you pet is not picked up by closing time, standard boarding
                fees will be added to you bill
              </p>
              <h3 id="q10">
                Should I wait on my pet while it is being groomed?
              </h3>
              <p>
                Grooming procedures can take several hours. We advise that you
                drop your pet off and come to pick it up later.
              </p>
              <h3 id="q11">
                What type of agreement is made when having a pet groomed?
              </h3>
              <p>
                A standard&nbsp;
                <a
                  title="Grooming Agreement"
                  href="http://shadylaneboardingkennel.com/grooming-agreement/"
                >
                  grooming agreement
                </a>
                &nbsp;is signed by both Shady Lane Boarding Kennel and the Pet
                Owner upon arrival.
              </p>
              <p>&nbsp;</p>
              <h2>Payment and Other Questions</h2>
              <h3 id="q12">When do we pay for your services?</h3>
              <p>We accept cash, cheque, or e-Transfer when you pick up your pet.</p>
              <h3 id="q13">What happens in an emergency?</h3>
              <p>
                We ask you to leave a contact number where you or your
                designated alternate may be contacted to take care of the
                situation. We will contact a local vet if the situation is
                urgent. This cost is at the owner’s expense.
              </p>
              <h3 id="q14">What about vaccines?</h3>
              <p>
                Rabies vaccines are mandatory in our county.&nbsp; We require
                proper documentation and a bordetella vaccine for dogs. Please
                see our{' '}
                <a href="http://shadylaneboardingkennel.com/vaccination-policy/">
                  vaccination policy
                </a>
                .
              </p>
            </Content>
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)
